import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Type from "./Type";
import laptopImg from "../../Assets/about.png";
import { useWindowSize } from 'react-use';

const Home = () => {
  
  const isMdOrAbove = useWindowSize('(min-width:992px)');
  
  return (
    <section>
      <Container fluid className="home-section" id="home">
        {isMdOrAbove ? <Particle /> : null}
        <Container className="home-content">
          <Row>
            <Col md={{span: 4}} className="home-header">
              
              <div style={{ "padding-top": 0, "margin-left": 25, textAlign: "left", opacity: "0.5", animation: "hue-rotate 15s 0s infinite linear"  }}>
                <Type />
              </div>
              <div style={{ "margin-left": 0, textAlign: "center", opacity: "0.75", animation: "hue-rotate 12s 0s infinite linear"}}>
                <Type />
              </div>
              <div style={{ "padding-top": 0, "margin-left": 25, textAlign: "right", opacity: "1", animation: "hue-rotate 9s 0s infinite linear" }}>
                <Type />
              </div>
            </Col>
            <Col md={{span: 4}} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ animation: "hue-rotate 6s 0s infinite linear", animationDirection: "alternate"}}
              />  
            </Col>
            <Col  md={{span: 4}}className="home-header">

              <div style={{ "padding-top": 0, "padding-left": 0, textAlign: "right", opacity: "0.5", animation: "hue-rotate 12s 0s infinite linear" }}>
                <Type />
              </div>
              <div style={{ "margin-left": 25, textAlign: "center", opacity: "0.75", animation: "hue-rotate 12s 0s infinite linear"  }}>
                <Type />
              </div>
              <div style={{ "padding-top": 0, "margin-left": 0, textAlign: "left", opacity: "1", animation: "hue-rotate 15s 0s infinite linear"  }}>
                <Type />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="about-section">
      <Particle />
      <Container>
       <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={12}
            style={{
              fontSize: "18px",
              justifyContent: "center",
              textAlign: "left",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
          <p style={{
              fontSize:"25px", textAlign:"center"}}>
                Sean Halls is a Los Angeles based artist, entrepreneur, and technologist.
              </p> 
        </Col>
        
        </Row>
      
      </Container>
    </Container>
    </section>
  );
}

export default Home;
