import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  /**
   * Randomize array element order in-place.
   * Using Durstenfeld shuffle algorithm.
   */
  function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array
}
 
  return (
    <Typewriter
      options={{
        strings:  shuffleArray([
          "Life Enthusiast",
          "Creator",
          "Musician",
          "TapeOp",
          "Tech Degenerate",
          "Dog Father",
          "World Traveler",
          "Husband",
          "Entertainer",
          "Entrepreneur",
          "Linux Advocate",
          "Streaky Chess Player",
          "Screenwriter",
          "Foodie",
          "Bob Ross Fan",
          "Type 1 Fun",
          "Investor",
          "Archivist",
          "Kerouac & Cognac",
          "Writer",
          "Good Surprises",
          "Angles",
        ]),
        autoStart: true,
        loop: true,
        deleteSpeed: 80,
      }}
    />
  );
}

export default Type;
