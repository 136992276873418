import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote style={{ textAlign: "left", fontSize: "17px" }} className="blockquote mb-0">
        <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple" style={{ animation: "hue-rotate 6s 0s infinite linear", animationDirection: "alternate"}}
             > INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I'm a lot of things. In some walks of life I am an artist. Some days, I create systems and applications. In all days, I find joy in the creations as they are made. Variety is the spice of life.
             <br /> <br /> 
              I'm a fullstack Software Developer with fourteen years of industry experience driving application and web development from inception to deployment. 
              Certified w/ advanced knowledge of algorithms and extensive experience in managing software teams to ensure quality and velocity in achieving development goals.
              <br />
              <br />
              Fluent in all the classics, mostly writing in Python or Javascript these days.
              <br />
              <br />
              Whenever possible, I also apply my passion for developing products
              with <b className="purple">Node.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> React.js & Next.js</b>
              </i>
            </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
